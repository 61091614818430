import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/common/Layout';
import Home from './components/home';
import AboutUs from './components/about-us';
import Blog from './components/blog';
import BlogPost from './components/blog/BlogPost';
import Books from './components/books';
import BookPosts from './components/books/BookPosts';
import ContactUs from './components/contact-us';
import MediaEpisodes from './components/media/MediaEpisodes';
import Login from './components/login';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Layout />}>
          {/* Login routes */}
          {/* Home Route */}
          <Route index element={<Home />} />

          {/* About Route */}
          <Route path='about' element={<AboutUs />} />

          {/* Blog Routes */}
          <Route path='blog' element={<Blog />} />
          <Route path='blog/:id' element={<BlogPost />} />

          {/* Media Routes */}
          <Route path='media'>
            <Route path=':slug' element={<MediaEpisodes />} />
          </Route>

          {/* Books Routes */}
          <Route path='books' element={<Books />} />
          <Route path='books/:id' element={<BookPosts />} />

          {/* Contact Route */}
          <Route path='contact' element={<ContactUs />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
