import React from 'react'
import AuthComment from '../blog/AuthComments'

const index = () => {
  return (
    <section>
      <AuthComment postId={0} postDate={''} />
    </section>
  )
}

export default index