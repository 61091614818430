import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import sidelineL from '../assets/book-sideline-l.png';
import sidelineR from '../assets/book-sideline-r.png';
import podcastbg1 from '../assets/podbgg1.png';
import podcastbg2 from '../assets/podbgg2.png';
import { useFetchBooks } from '../api/useFetchBooks';
import { decodeHtmlEntities, formatedBookContent } from '../utils/utils';
import parse from 'html-react-parser';
import CircleLoader from '../utils/Loader';
import CustomAlert from '../utils/CustomAlert';

interface Book {
  id: number;
  title: { rendered: string };
  content: { rendered: string };
  date: string;
  excerpt: { rendered: string };
}

const BlogBook: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [book, setBook] = useState<Book | null>(null);

  const { books, loading, error } = useFetchBooks({
    per_page: 12,
    orderby: 'date',
    order: 'desc',
  });

  useEffect(() => {
    if (books.length > 0 && id) {
      const foundBook = books.find((p) => p.id.toString() === id);
      if (foundBook) {
        setBook(foundBook);
      }
    }
  }, [books, id]);

  const { imageUrl, formatedContent, amazonUrl } = book
    ? formatedBookContent(book.content.rendered)
    : { imageUrl: null, formatedContent: '', amazonUrl: '' };

  return (
    <section className='relative'>
      <div>
        <img
          src={sidelineL}
          alt=''
          className='md:w-1/4 w-1/3 absolute -start-2 top-20'
        />
        <img
          src={sidelineR}
          alt=''
          className='md:w-1/4 w-1/3 absolute end-0 top-20'
        />
      </div>
      {loading ? (
        <div className='flex justify-center items-center min-h-[50vh]'>
          <CircleLoader />
        </div>
      ) : error ? (
        <div className='col-span-full flex justify-center items-center min-h-[50vh]'>
          <CustomAlert variant='error'>{error} Books</CustomAlert>
        </div>
      ) : !book ? (
        <div>Blog not found</div>
      ) : (
        <article className='container mx-auto px-4 py-8 relative md:pt-24 pt-20'>
          <Link to='/books' className='hover:underline mb-4 inline-block mt-20'>
            &larr; Back to all books
          </Link>
          <div className=''>
            <h2 className='text-3xl font-bold mb-2 text-center'>
              {decodeHtmlEntities(book.title.rendered)}
            </h2>
            <span className='text-gray-500 mb-8 block text-center'>
              {new Date(book.date).toLocaleDateString()}
            </span>
            {imageUrl && (
              <img
                src={imageUrl}
                alt={book.title.rendered}
                className='w-full md:w-5/12 h-5/12 mx-auto mb-5 rounded-md'
              />
            )}
            <div className='w-full md:w-5/12 mx-auto text-xl space-y-5'>
              {parse(formatedContent)}
            </div>
          </div>

          <Link
            target='_blank'
            to={amazonUrl as string}
            className='text-sm md:py-2.5 py-3 text-white px-12 md:my-24 mt-14 mb-20 flex justify-center md:w-1/5 w-1/2 mx-auto bg-black object-contain'>
            BUY NOW
          </Link>
        </article>
      )}
      <div className=''>
        <img
          src={podcastbg1}
          alt=''
          className='w-[6rem] absolute start-0 bottom-0'
        />
        <img
          src={podcastbg2}
          alt=''
          className='w-[3.5rem] absolute end-0 bottom-0'
        />
      </div>
    </section>
  );
};

export default BlogBook;
